<template>
  <div class="relevance">
    <a-row>
      <a-col class="relevance-title" :span="12" align="left">
          买家关联签署人
        </a-col>
        <a-col :span="12" align="right"><a-button @click="$emit('rele', true)" type="primary">返回上一步</a-button></a-col>
      <a-col :span="8">签署人姓名：{{ singner.signerName}}</a-col>
      <a-col :span="8">身份证号：{{ singner.signerIdCard }}</a-col>
      <a-col :span="8" align="center">签署人手机号：{{ singner.signerMobile }}</a-col>
      
    </a-row>
    <a-row type="flex">
      <a-col :span="24" class="relevance-title">关联卖家</a-col>
      <!-- <a-col :span="3">
        <a-button>批量删除</a-button>
      </a-col> -->
      <a-col :span="3">
        <a-button @click="visible = true">添加关联</a-button>
      </a-col>
    </a-row>
    <a-table
      rowKey="supplierId"
      :columns="columns"
      :data-source="list"
      :pagination="{
        size: 'default',
        total: getsellerlist.total,
        current: getsellerlist.pageNum,
        pageSize: getsellerlist.pageSize,
        showSizeChanger: true,
        pageSizeOptions: ['10', '20', '50', '100'],
        showTotal: (total) => `共有 ${getsellerlist.total} 条数据`,
        showQuickJumper: true,
        onShowSizeChange: (current, pageSize) => {
          getsellerlist.pageSize = pageSize
          getsellerlist.pageNum = 1
          this.getsign()
        },
        onChange: (pageNum) => {getsellerlist.pageNum = pageNum
          this.getsign()
        }
      }"
    >
      <template slot="action" slot-scope="text, record">
        <a-popconfirm
          cancelText="取消"
          okText="确定"
          title="确定删除?"
          :disabled="editingKey !== ''"
          @confirm="() => onDelete(record.id)"
        >
          <a href="javascript:;">删除</a>
        </a-popconfirm>
      </template>
    </a-table>
    <!-- 添加关联卖家 -->
    <a-modal
      :visible="visible"
      @cancel="visible = false"
      footer
      :width="640"
      :centered="true"
      title="添加关联卖家"
      :maskClosable="false"
    >
      <a-row type="flex" align="middle">
        <!-- <a-col :span="24" class="pt10">添加关联卖家</a-col> -->
        <!-- <a-col :span="3" class="pt10">搜索：</a-col> -->
        <!-- <a-col :span="21"
          ><a-input-search
            placeholder="输入交易主体或个人姓名"
            style="width: 370px"
            @search="onSearch"/>
            </a-col> -->
        <a-col :span="24" class="pt10">
          <a-button @click="addsinger()" :loading="loading">确认添加</a-button>
        </a-col>
        <a-table
          rowKey="supplierId"
          :row-selection="rowSelection"
          :columns="column"
          :data-source="addRele"
          :pagination="false"
          :scroll="{ y: 500 }"
          style="width: 100%"
        >
        </a-table>
      </a-row>
    </a-modal>
  </div>
</template>
<script>
import {
  signset,
  findsupplierall,
  updateflag,
  relevancysup
} from '../../../api/buyerCenter'
const columns = [
  {
    title: '交易主体ID',
    dataIndex: 'supplierId'
  },
  {
    title: '小规格纳税人名称',
    dataIndex: 'supplierName'
  },
  {
    title: '法人姓名',
    dataIndex: 'legalName'
  },
  {
    title: '操作',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' }
  }
]
const column = [
  {
    title: '交易主体ID',
    dataIndex: 'supplierId'
  },
  {
    title: '小规格纳税人名称',
    dataIndex: 'supplierName'
  },
  {
    title: '法人姓名',
    dataIndex: 'legalName'
  }
]

export default {
  props: ['id'],
  data() {
    return {
      visible: false,
      columns,
      column,
      editingKey: '',
      singner: {
        signerName: '',
        signerIdCard: '',
        signerMobile: ''
      },
      addRele: [],
      list: [],
      ids: this.id,
      getsellerlist: {
        pageNum: 1,
        pageSize: 10,
        totalPage: 1,
        total: 1
      },
      supplierId: [],
      loading: false
    }
  },
  mounted() {
    if (this.id !== null) {
      this.getsign()
      this.addRelevance()
    }
  },
  computed: {
    // rowSelection1() {
    //   return {
    //     onChange: (selectedRowKeys, selectedRows) => {
    //       console.log(
    //         `selectedRowKeys: ${selectedRowKeys}`,
    //         'selectedRows: ',
    //         selectedRows
    //       )
    //     }
    //   }
    // },
    rowSelection() {
      return {
        selectedRowKeys: this.supplierId,
        onChange: this.onSelectChange,
        getCheckboxProps: (record) => ({
          props: {
            disabled:
              record.id !==
              this.list.find((item) => {
                item.id
              })
          }
        })
      }
    }
  },
  methods: {
    onSelectChange(selectedRowKeys) {
      this.supplierId = selectedRowKeys
    },
    getsign() {
      //买家中心返回签署人列表下所有关联卖家
      signset({
        id: this.ids,
        pageNum: this.getsellerlist.pageNum,
        pageSize: this.getsellerlist.pageSize
      }).then((res) => {
        this.getsellerlist.pageNum = res.data.commonPage.pageNum
        this.getsellerlist.pageSize = res.data.commonPage.pageSize
        this.getsellerlist.totalPage = res.data.commonPage.totalPage
        this.getsellerlist.total = res.data.commonPage.total
        this.list = res.data.commonPage.list
        this.singner.signerName = res.data.signerName
        this.singner.signerIdCard = res.data.signerIdCard
        this.singner.signerMobile = res.data.signerMobile
      })
    },
    // 添加关联获取列表
    addRelevance() {
      findsupplierall({
        entityId: this.$route.query.entityId,
        purchaserSingerId: this.id
      }).then((res) => {
        this.addRele = res.data
      })
    },
    // 确认添加关联
    addsinger() {
      this.loading = true
      if (this.supplierId.length == 0) {
        this.$message.error('请选择关联人')
        this.loading = false
        return
      } else
        relevancysup({
          purchaserId: this.$route.query.entityId,
          purchaserSingerId: this.ids,
          list: this.supplierId
        })
          .then((res) => {
            this.loading = false
            if (res.code === 200) {
              this.getsign()
              this.addRelevance()
              this.supplierId = []
              this.$message.success(res.message)
            } else {
              this.$message.error(res.message)
            }
          })
          .catch((r) => {
            console.log(r)
            this.loading = false
          })
    },
    // 删除
    onDelete(id) {
      updateflag({ id: id }).then((res) => {
        if (res.code === 200) {
          this.getsign()
          this.addRelevance()
          this.$message.success(res.message)
        } else {
          this.$message.error(res.message)
        }
      })
    },
    onSearch() {
    }
  }
}
</script>
<style lang="less" scoped>
.relevance {
  padding: 20px;
  background-color: #ffffff;
  .relevance-title {
    font-weight: 700;
    font-size: 14px;
    color: rgba(0, 0, 0, 0.847058823529412);
    line-height: 24px;
  }
}
/deep/ .pt10 {
  padding: 10px 0;
}
</style>