<template>
  <a-modal
    title="添加签署公章"
    :visible="visible"
    :maskClosable="false"
    :confirmLoading="confirmLoading"
    :width="800"
    :footer="null"
    @cancel="cancelHandel"
  >
    <a-row>
      <a-col :xs="24" :md="12" :style="{ height: '350px' }">
        <!-- <VueCropper
          v-if="options.img"
          ref="cropper"
          :img="options.img"
          :info="true"
           outputType="png"
          :autoCrop="options.autoCrop"
          :autoCropWidth="options.autoCropWidth"
          :autoCropHeight="options.autoCropHeight"
          :enlarge="3"
          :fixedBox="options.fixedBox"
          @realTime="realTime"
        >
        </VueCropper> -->
        <vue-cropper
          style="height: 100%"
          ref="cropper"
          v-if="options.img"
          :src="options.img"
          @crop="tests"
        >
        </vue-cropper>
      </a-col>
      <a-col :xs="24" :md="12" :style="{ height: '350px' }" class="Imag">
        <VueImageHandler
          v-if="previews.url"
          ref="vueImageHandler"
          canvas-width="170px"
          canvas-height="170px"
          :img-file="previews.url"
          :wipe-color="test ? '' : 'white'"
          :color-diff="100"
        />
      </a-col>
    </a-row>
    <br />
    <a-row>
      <a-col :lg="2" :md="2"> </a-col>
      <a-col :lg="{ span: 1, offset: 2 }" :md="2">
        <a-button icon="plus" @click="changeScale(1)" />
      </a-col>
      <a-col :lg="{ span: 1, offset: 1 }" :md="2">
        <a-button icon="minus" @click="changeScale(-1)" />
      </a-col>
      <a-col :lg="{ span: 1, offset: 1 }" :md="2">
        <a-button icon="undo" @click="rotateLeft" />
      </a-col>
      <a-col :lg="{ span: 1, offset: 1 }" :md="2">
        <a-button icon="redo" @click="rotateRight" />
      </a-col>
      <a-col :lg="{ span: 2, offset: 6 }" :md="2">
        <a-button type="primary" @click="finish()" :loading="loading"
          >保存</a-button
        >
      </a-col>
    </a-row>
  </a-modal>
</template>
<script>
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import { uploadPhoto } from '../../../api/utils'
import { uploadseal } from '@/api/buyerCenter'
// import { VueImageHandler } from 'vue-image-handler/lib/vue-image-handler.umd.min.js'
let VueImageHandler = {}
if(navigator.userAgent.toLowerCase().indexOf('trident')===-1) {
  console.log('?????ie');
  VueImageHandler = require('vue-image-handler/lib/vue-image-handler.umd.min.js').VueImageHandler
  console.log(VueImageHandler);
}
export default {
  components: {
    VueCropper,
    VueImageHandler
  },
  data() {
    return {
      visible: false,
      id: null,
      confirmLoading: false,
      fileList: [],
      uploading: false,
      options: {
        img: '',
        autoCrop: true,
        autoCropWidth: 170,
        autoCropHeight: 170,
        fixedBox: true,
      },
      previews: {
        url: ''
      },
      loading: false,
      test: false,
    }
  },
  watch: {
    'previews.url'() {
      this.test = true
      this.$nextTick(() => {
        this.test = false
      })
      
    }
  },
  methods: {
    // bs64转File
    dataURLtoFile(dataurl, fileName) {
      if (!dataurl) return
      dataurl = dataurl.replace(/\n/g, '')
      let arr = dataurl.split(',')
      let mime = arr[0].match(/:(.*?);/)[1]
      let bstr = atob(arr[1])
      let n = bstr.length
      let u8arr = new Uint8Array(n)
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }
      return new File([u8arr], fileName, { type: mime })
    },
    edit(id) {
      this.visible = true
      this.id = id
      /* 获取原始头像 */
    },
    close() {
      this.id = null
      this.visible = false
      this.options.img = ''
      this.$refs.vueImageHandler.clear()
      this.previews.url=''
    },
    cancelHandel() {
      this.close()
      this.$refs.vueImageHandler.clear()
    },
    changeScale(num) {
      num = num || 1
      // this.$refs.cropper.changeScale(num)
      // this.$refs.cropper.getCropData((data) => {
      // this.previews.url = data
      // })
      this.$refs.cropper.relativeZoom(num);
    },
    rotateLeft() {
      // this.$refs.cropper.rotateLeft()
      // this.$refs.vueImageHandler.rotate()
      this.$refs.cropper.rotate(45);
    },
    rotateRight() {
      // this.$refs.cropper.rotateRight()
      this.$refs.cropper.rotate(-45);
    },

    // 上传图片（点击上传按钮）
    finish() {
      // 输出
      this.loading = true
      if(navigator.userAgent.toLowerCase().indexOf('trident')>-1) {
        uploadPhoto(this.dataURLtoFile(this.previews.url, 'seal.png'), (obj) => {
          uploadseal({
            purchaserId: this.$route.query.entityId,
            sealUrl: obj
          }).then((res) => {
            this.loading = false      
            if (res.code === 200) {
              this.$notification.success({
                message: '成功',
                description: '添加成功！'
              })
              // this.$refs.vueImageHandler.clear()
              this.visible = false
              this.$emit('getSealList')
            } else {
              this.$notification.error({
                message: '错误',
                description: res.message
              })
            }
          })
        })
        return
      } 
      this.$refs.vueImageHandler.getImageUrl((url) => {
        uploadPhoto(this.dataURLtoFile(url, 'seal.png'), (obj) => {
          uploadseal({
            purchaserId: this.$route.query.entityId,
            sealUrl: obj
          }).then((res) => {
            this.loading = false      
            if (res.code === 200) {
              this.$notification.success({
                message: '成功',
                description: '添加成功！'
              })
              this.$refs.vueImageHandler.clear()
              this.visible = false
              this.$emit('getSealList')
            } else {
              this.$notification.error({
                message: '错误',
                description: res.message
              })
            }
          })
        })
      })
      this.loading = true
    },
    realTime(data) {
      data.w &&
        this.$refs.cropper.getCropData((data) => {
          this.previews.url = data
        })
    },
    tests() {
      this.previews.url = this.$refs.cropper ? this.$refs.cropper.getCroppedCanvas().toDataURL() : ''
    }
  }
}
</script>

<style lang="less" scoped>
.avatar-upload-preview {
  position: absolute;
  top: 50%;
  transform: translate(50%, -50%);
  width: 170px;
  height: 170px;
  box-shadow: 0 0 4px #ccc;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
  }
}
.Imag {
  display: flex;
  justify-content: center;
  align-items: center;
}
/deep/.crop-wrapper {
  opacity: 0;
  display: none;
}
</style>
