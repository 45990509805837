<template>
  <div class="signset">
    <div class="mainbody" v-show="rele">
      <!--  -->
      <div class="sign-title">签署设置</div>
      <a-row type="flex">
        <span>签署公章：</span>
        <div class="clearfix" style="min-width: 500px">
          <a-upload
            :customRequest="customRequest"
            accept="image/png"
            list-type="picture-card"
            :file-list="purchaserSealList"
            @preview="handlePreview"
            :remove="onSealDelete"
            :disabled="!loading"
          >
            <div v-if="purchaserSealList.length < 8 && loading">
              <a-row>
                <a-icon type="plus" />
              </a-row>
              <div class="ant-upload-text">点击上传</div>
            </div>
            <a-icon v-if="!loading" style="font-size: 30px" type="loading" />
          </a-upload>
          <div class="message-text">
            <span class="tit">上传公章规格要求：</span
            >170*170PX，背景透明，png格式。若不上传公章，则无法发起交易合同
          </div>
          <a-modal
            :visible="previewVisible"
            :footer="null"
            @cancel="handleCancel"
          >
            <img alt="example" style="width: 100%" :src="previewImage" />
          </a-modal>
        </div>
      </a-row>
      <div class="sign-title">默认签署人</div>
      <a-row type="flex" align="middle">
        <a-col :span="2" class="lh32">  签署人姓名:</a-col>
        <a-col :span="4" >
          <a-input
            v-if="sign"
            v-model="defaultSigner.defaultSignerName"
          ></a-input
          ><span v-else>{{ defaultSigner.defaultSignerName }}</span>
        </a-col>
        <a-col :span="4" align="right" class="lh32">身份证号：</a-col>
        <a-col :span="4"><a-input
            v-if="sign"
            v-model="defaultSigner.defaultSignerIdCard"
            :maxLength="18"
          ></a-input
          ><span v-else>{{ defaultSigner.defaultSignerIdCard }}</span></a-col
        >
        <a-col :span="3" class="lh32" align="right">手机号：</a-col>
        <a-col :span="5"><a-input
            v-if="sign"
            v-model="defaultSigner.defaultSignerMobile"
            :maxLength="11"
            @input="defaultSigner.defaultSignerMobile=$event.target.value.replace(/[^\d]/g,'')"
          ></a-input
          ><span v-else>{{ defaultSigner.defaultSignerMobile }}</span></a-col
        >

        <a-col :span="24" align="right">
          <a-button v-if="!sign" type="primary " @click="sign = true"
            >修改签署人</a-button
          >
          <span v-else><a-button type="primary" @click="updateSingner()">确认</a-button
            >&nbsp;&nbsp;<a-button
              @click="
                getfindsupplierall()
                sign = false
              "
              >取消</a-button></span>
            </a-col
        >
      </a-row>
      <a-row>
        <a-col :span="23" align="right">
          <!-- 与关联签署人关联以外的卖家发生交易时，订单签署人为默认签署人 -->
        </a-col>
      </a-row>
      <div class="sign-title">关联签署人</div>
      <!--  -->
      <a-row>
        <!-- <a-col :span="1" class="lh32">搜索:</a-col>
        <a-col :span="12" align="left"
          ><a-input-search
            placeholder="可模糊搜索姓名、关联卖家"
            style="width: 430px"
            @search="onSearch"/>
            </a-col> -->
      </a-row>
      <a-table
        :data-source="signToryVoList"
        :columns="columns"
        :pagination="false"
        rowKey="id"
      >
        <template
          v-for="col in ['signerName', 'signerIdCard', 'signerMobile']"
          :slot="col"
          slot-scope="text, record"
        >
          <div :key="col">
            <a-input
              v-if="record.editable"
              :value="text"
              :maxLength="col | length"
              @change="(e) => handleChange(e.target.value, record.id, col)"
            />
            <template v-else>
              {{ text }}
            </template>
          </div>
        </template>

        <template slot="isLegal" slot-scope="text">
          <a-icon
            v-if="text"
            type="check-circle"
            theme="filled"
            class="font-error"
          />
        </template>
        <template slot="isDefault" slot-scope="text">
          <a-icon
            v-if="text == 1"
            type="check-circle"
            theme="filled"
            class="font-error"
          />
        </template>
        <template slot="action" slot-scope="text, record">
          <a-space v-if="record.editable">
            <a-icon type="check" @click="() => save(record)"></a-icon>
            <a-icon type="close" @click="() => cancel(record.id)"></a-icon>
          </a-space>
          <a-space v-else>
            <a :disabled="editingKey !== ''" @click="() => relevance(record.id)"
              >关联卖家</a
            >&nbsp;
            <a v-if="record.isDefault!==1" :disabled="editingKey !== ''" @click="() => edit(record)"
              >编辑</a
            >&nbsp;
            <a-popconfirm
             v-if="record.isDefault!==1"
              cancelText="取消"
              okText="确定"
              title="确定删除?"
              :disabled="editingKey !== ''"
              @confirm="() => onDelete(record.id)"
            >
              <a href="javascript:;">删除</a>
            </a-popconfirm>
          </a-space>
        </template>
      </a-table>
      <div
        class="table-foot"
        @click="addS"
        v-if="!signToryVoList.find((item) => item.id === -1)"
      >
        <a-icon type="plus"></a-icon>
      </div>
    </div>
    <Relevance v-if="!rele" :id="id" @rele="rele = $event;getfindsupplierall()"></Relevance>
    <avatar-modal  ref="modal"  @getSealList="getSealList()"/>
  </div>
</template>

<script>
import {
  signtory,
  add,
  updatesignerpurchase,
  updatedefaultsign,
  delsignerpurchaser,
  setdefaultseal,
  list,
  deleteseal,
} from '../../../api/buyerCenter'
import AvatarModal from './AvatarModal.vue'
import Relevance from './Relevanc.vue'
const columns = [
  {
    title: '姓名',
    dataIndex: 'signerName',
    width: '15%',
    scopedSlots: { customRender: 'signerName' }
  },
  {
    title: '身份证号',
    dataIndex: 'signerIdCard',
    width: '25%',
    scopedSlots: { customRender: 'signerIdCard' }
  },
  {
    title: '手机号',
    dataIndex: 'signerMobile',
    width: '25%',
    scopedSlots: { customRender: 'signerMobile' }
  },
  {
    title: '关联卖家数量',
    align:'center',
    dataIndex: 'relCount',
    scopedSlots: { customRender: 'relCount' }
  },
  {
    title: '操作',
    dataIndex: 'action',
    scopedSlots: { customRender: 'action' }
  }
]
function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
}
var myreg = /^[1][3-9][0-9]{9}$/
var car =/^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/;
export default {
  components: {
    Relevance,
    AvatarModal
  },
  data() {
    this.cacheData = []
    return {
      loading: true,
      extSignNum: '', //公章数字
      previewImage: '', //公章图片
      previewVisible: false,
      rele: true,
      sign: false,
      columns,
      editingKey: '',
      // 主键id
      id: null,
      // 默认签署人
      defaultSigner: {
      defaultSignerName: '',
      defaultSignerIdCard: '',
      defaultSignerMobile: ''
      },
      //列表内容
      signToryVoList: [],
      purchaserSealList: [],
      file:'',
      defaultClass: 'ant-upload-list-item ant-upload-list-item-done ant-upload-list-item-list-type-picture-card'
    }
  },
  created() {
    this.getfindsupplierall()
    this.getSealList()
  },
  destroyed() {
    // 删除监听印章点击事件
    // eslint-disable-next-line no-debugger
    document.removeEventListener('click', this.clickChange)
  },
  mounted() {
    // 添加印章点击事件
    document.addEventListener('click', this.clickChange)
  },
  filters: {
    length(val) {
      if (!val) return ''
      const obj = {
        signerName: 6,
        signerIdCard: 18,
        signerMobile: 11
      }
      return obj[val] || ''
    }
  },

  methods: {
    setavatar(url) {
      this.previewImage = url
    },
    // 设置默认公章
    clickChange(ev) {
      const that = this
      var target = ev.target || ev.srcElement
      if (target.className === 'ant-upload-list-item-info') {
        that.$confirm({
          title: '提示',
          content: '确定设置成默认公章？',
          onOk: () => {
            const uid = target.getAttribute('uId')
            setdefaultseal({
              sealId: uid,
              purchaserId: that.$route.query.entityId
            }).then((res) => {
              if (res.code === 200) {
                // document.getElementById('icon-default').remove()
                that.$notification.success({
                  message: '成功',
                  description: res.message
                })
                that.getSealList()
              } else {
                that.$notification.error({
                  message: '错误',
                  description: res.message
                })
              }
            })
          },
          onCancel() {}
        })
      }
    },
    // 修改默认签署人
    updateSingner() {
      if (!this.defaultSigner.defaultSignerName) {
        this.$message.error('请输入签署人名称')
      } else if (!car.test(this.defaultSigner.defaultSignerIdCard)) {
        this.$message.error('请输入签署人身份证号')
      } else if (!myreg.test(this.defaultSigner.defaultSignerMobile)) {
        this.$message.error('请输入正确的签署人手机号')
      } else
        return updatedefaultsign({
          ...this.defaultSigner,
          id: this.$route.query.entityId
        }).then((res) => {
          if (res.code === 200) {
            this.sign = false
            this.getfindsupplierall()
            this.$message.success(res.message)
          } else {
            this.$message.error(res.message)
          }
        })
    },
    // 签署人列表
    getfindsupplierall() {
      // 主体下签署人列表
      signtory({
        entityCharacter: 'P',
        entityId: this.$route.query.entityId
      }).then((res) => {
        this.defaultSigner.defaultSignerName = res.data.defaultSignerName
        this.defaultSigner.defaultSignerIdCard = res.data.defaultSignerIdCard
        this.defaultSigner.defaultSignerMobile = res.data.defaultSignerMobile
        this.signToryVoList = res.data.signToryVoList
        this.cacheData = res.data.signToryVoList.map((item) => {
          return { ...item }
        })
        this.purchaserSealList = res.data.purchaserSealList.map((item) => {
          return {
            uid: item.id,
            status: 'done',
            isDefault: item.isDefault,
            name: item.extSignNum,
            url: item.sealUrl
          }
        })
        // this.setSellNumb()
      })
    },
    onSearch() {},
    // 关联签署人
    relevance(id) {
      this.id = id
      this.rele = false
    },
    // 编辑
    edit(key) {
      const newData = [...this.signToryVoList]
      const target = newData.filter((item) => key=== item.id)[0]
      target.data = target
      this.editingKey = key
      if (target) {
        target.editable = true
        this.signToryVoList = newData
      }
    },
    // 删除
    onDelete(id) {
      delsignerpurchaser({ id: id }).then((res) => {
        if (res.code === 200) {
          this.getfindsupplierall()
          this.$message.success(res.message)
        } else {
          this.$message.error(res.message)
        }
      })
    },
    handleChange(value, key, column) {
      const newData = [...this.signToryVoList]
      const target = newData.filter((item) => key === item.id)[0]
      if (target) {
        target[column] = value
        this.signToryVoList = newData
      }
    },
    // 卖家签署人添加操作
    addAction(obj) {
      const objs = {
        purchaserId: this.$route.query.entityId,
        signerIdCard: obj.signerIdCard,
        signerMobile: obj.signerMobile,
        signerName: obj.signerName
      }
      add(objs).then((res) => {
        if (res.code === 200) {
          this.editingKey = ''
          this.$message.success(res.message)
          this.getfindsupplierall()
        } else {
          this.$message.error(res.message)
        }
      })
    },
    addS() {
      if (this.cacheData.find((item) => item.key === -1)) return
      const obj = {
        id: -1,
        signerIdCard: '',
        signerMobile: '',
        signerName: ''
      }
      this.signToryVoList.push(obj)
      this.cacheData.push(obj)
      this.edit(obj.id)
    },
    // 编辑
    save(record) {
      if (!record.signerName) {
        this.$message.error('名字不能为空')
      } else if (!car.test(record.signerIdCard)) {
        this.$message.error('请输入正确的身份证')
      } else if (!myreg.test(record.signerMobile)) {
        this.$message.error('请输入正确的手机号')
      } else {
        if (record.id === -1) {
          this.addAction(record)
        } else {
          updatesignerpurchase({
            id: record.id,
            signerName: record.signerName,
            signerIdCard: record.signerIdCard,
            signerMobile: record.signerMobile
          }).then((res) => {
            if (res.code === 200) {
              this.editingKey = ''
              this.$message.success(res.message)
              this.getfindsupplierall()
            } else {
              this.$message.error(res.message)
            }
          })
        }
      }
    },
    // 公章操作
    setSellNumb() {
      if (document.getElementById('icon-default')) {
        document.getElementById('icon-default').remove()
      }
      const uploadItem = document.getElementsByClassName('ant-upload-list-item')
      const imgBox = document.getElementsByClassName(
        'ant-upload-list-item-info'
      )
      let chArr = document.body.getElementsByClassName("purchaser-id");
      if (chArr.length) {
        for(var i = chArr.length - 1; i >= 0; i--){
          if (chArr[i]) {
            chArr[i].parentNode.removeChild(chArr[i]); 
          }
        }
      }
      this.$nextTick(() => {
        setTimeout(() => {
        this.purchaserSealList.forEach((item, index) => {
          var div = document.createElement('div')
          var txt = document.createTextNode(item.name)
          div.className = 'purchaser-id'
          div.appendChild(txt)
          // 如果是默认印章
          if (item.isDefault) {
            uploadItem[index].className = this.defaultClass + ' rell-defailt'
          } else {
            uploadItem[index].className = this.defaultClass
          }
          imgBox[index].setAttribute('uId', item.uid)
          uploadItem[index].appendChild(div)
        })
        }, 310)
      })
    },
   // 公章列表
    getSealList () {
      list({ purchaserId: this.$route.query.entityId }).then(res => {
        if (res.code === 200) {
          this.purchaserSealList = res.data.map(item => {
            return {
              uid: item.id,
              name: `${item.extSignNum}`,
              status: 'done',
              url: `${item.sealUrl}`,
              isDefault: item.isDefault
            }
          })
          this.setSellNumb()
        } else {
          this.$notification.error({
            message: '错误',
            description: res.message
          })
        }
      })
    },
    handleCancel() {
      this.previewVisible = false
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj)
      }
      this.previewImage = file.url || file.preview
      this.previewVisible = true
    },
    cancel(key) {
      if (key === -1) {
        const newData = [...this.signToryVoList]
        this.signToryVoList = newData.filter((item) => key !== item.id)
        this.cacheData = [...this.signToryVoList]
        this.editingKey = ''
      } else {
        const newData = [...this.signToryVoList]
        const target = newData.filter((item) => key === item.id)[0]
        this.editingKey = ''
        if (target) {
          Object.assign(
            target,
            this.cacheData.filter((item) => key === item.id)[0]
          )
          delete target.editable
          this.signToryVoList = newData
        }
      }
    },
    // 删除公章
    onSealDelete(file) {
      if (document.getElementById('icon-default')) {
        document.getElementById('icon-default').remove()
      }
      this.$confirm({
        title: '提示',
        content: '确定删除？',
        onOk: () => {
          deleteseal({ sealId: file.uid }).then((res) => {
            if (res.code === 200) {
              this.$notification.success({
                message: '成功',
                description: '删除成功！'
              })
              this.getSealList()
            } else {
              this.$notification.error({
                message: '错误',
                description: res.message
              })
            }
          })
        },
        onCancel() {}
      })
    },
    getBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = (error) => reject(error)
      })
    },
    async customRequest({ file }) {
      this.$nextTick(async ()=>{
        this.$refs.modal.options.img =await this.getBase64(file)
         this.$refs.modal.previews.url =await this.getBase64(file)
         this.$refs.modal.edit(1)
      })
    }
  }
}
</script>
<style lang="less" scoped>
.signset {
  border-top: 1px solid #ccc;
  background-color: #ffffff;
  .mainbody {
    padding: 20px 30px;
    .sign-title {
      font-size: 16px;
      font-weight: 650;
      color: rgba(0, 0, 0, 0.847058823529412);
      padding: 10px 0;
    }
    .lh32 {
      line-height: 30px;
    }
    .lh44 {
      line-height: 44px;
    }
    .color {
      color: rgb(24, 144, 255);
    }
    .bgc {
      background-color: rgb(24, 144, 255);
    }
    .dynamic-delete-button {
      cursor: pointer;
      position: relative;
      top: 4px;
      font-size: 24px;
      color: #999;
      transition: all 0.3s;
    }
    .dynamic-delete-button:hover {
      color: #777;
    }
    .dynamic-delete-button[disabled] {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }
}
.table-foot {
  text-align: center;
  border-bottom: 1px solid #e8e8e8;
  height: 46px;
  line-height: 46px;
  cursor: pointer;
  user-select: none;
}

/deep/ .ant-table-placeholder {
  display: none;
}
.table-foot {
  text-align: center;
}
.product-img {
  width: 88px;
  height: 88px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: rgba(0, 0, 0, 0.427450980392157);
  background-color: #eee;
  border-radius: 5%;
  img {
    width: 66px;
  }
  .img-config {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
  }
}
/deep/ .purchaser-id {
  position: absolute;
  // color: red;
  z-index: 2;
  bottom: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  left: 0;
}
/deep/ .rell-defailt {
  border-color: red;
  position: relative;
  &::after {
    content: '';
    height: 20px;
    width: 20px;
    background-image: url('../../../assets/images/default.png');
    background-size: cover;
    position: absolute;
    right: 0;
    top: 0;
  }
  &::before {
    content: '';
    width: 20px;
    background-size: cover;
    position: absolute;
    right: 0;
    bottom: 0;
  }
}
</style>