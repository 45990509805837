var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"relevance"},[_c('a-row',[_c('a-col',{staticClass:"relevance-title",attrs:{"span":12,"align":"left"}},[_vm._v(" 买家关联签署人 ")]),_c('a-col',{attrs:{"span":12,"align":"right"}},[_c('a-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.$emit('rele', true)}}},[_vm._v("返回上一步")])],1),_c('a-col',{attrs:{"span":8}},[_vm._v("签署人姓名："+_vm._s(_vm.singner.signerName))]),_c('a-col',{attrs:{"span":8}},[_vm._v("身份证号："+_vm._s(_vm.singner.signerIdCard))]),_c('a-col',{attrs:{"span":8,"align":"center"}},[_vm._v("签署人手机号："+_vm._s(_vm.singner.signerMobile))])],1),_c('a-row',{attrs:{"type":"flex"}},[_c('a-col',{staticClass:"relevance-title",attrs:{"span":24}},[_vm._v("关联卖家")]),_c('a-col',{attrs:{"span":3}},[_c('a-button',{on:{"click":function($event){_vm.visible = true}}},[_vm._v("添加关联")])],1)],1),_c('a-table',{attrs:{"rowKey":"supplierId","columns":_vm.columns,"data-source":_vm.list,"pagination":{
      size: 'default',
      total: _vm.getsellerlist.total,
      current: _vm.getsellerlist.pageNum,
      pageSize: _vm.getsellerlist.pageSize,
      showSizeChanger: true,
      pageSizeOptions: ['10', '20', '50', '100'],
      showTotal: function (total) { return ("共有 " + (_vm.getsellerlist.total) + " 条数据"); },
      showQuickJumper: true,
      onShowSizeChange: function (current, pageSize) {
        _vm.getsellerlist.pageSize = pageSize
        _vm.getsellerlist.pageNum = 1
        this$1.getsign()
      },
      onChange: function (pageNum) {_vm.getsellerlist.pageNum = pageNum
        this$1.getsign()
      }
    }},scopedSlots:_vm._u([{key:"action",fn:function(text, record){return [_c('a-popconfirm',{attrs:{"cancelText":"取消","okText":"确定","title":"确定删除?","disabled":_vm.editingKey !== ''},on:{"confirm":function () { return _vm.onDelete(record.id); }}},[_c('a',{attrs:{"href":"javascript:;"}},[_vm._v("删除")])])]}}])}),_c('a-modal',{attrs:{"visible":_vm.visible,"footer":"","width":640,"centered":true,"title":"添加关联卖家","maskClosable":false},on:{"cancel":function($event){_vm.visible = false}}},[_c('a-row',{attrs:{"type":"flex","align":"middle"}},[_c('a-col',{staticClass:"pt10",attrs:{"span":24}},[_c('a-button',{attrs:{"loading":_vm.loading},on:{"click":function($event){return _vm.addsinger()}}},[_vm._v("确认添加")])],1),_c('a-table',{staticStyle:{"width":"100%"},attrs:{"rowKey":"supplierId","row-selection":_vm.rowSelection,"columns":_vm.column,"data-source":_vm.addRele,"pagination":false,"scroll":{ y: 500 }}})],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }